var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { value: _vm.value, persistent: "", "max-width": "950px" },
      on: { "click:outside": _vm.close }
    },
    [
      _c(
        "v-card",
        { attrs: { flat: "" } },
        [
          _c(
            "v-toolbar",
            [
              _c(
                "v-toolbar-title",
                [
                  _c("v-card-title", [
                    _vm._v(
                      " " +
                        _vm._s(
                          '"' + _vm.selectedContract.file_name + '" Payload'
                        ) +
                        " "
                    )
                  ])
                ],
                1
              ),
              _c("v-spacer"),
              _c(
                "v-btn",
                { attrs: { icon: "" }, on: { click: _vm.close } },
                [_c("v-icon", [_vm._v("mdi-close")])],
                1
              )
            ],
            1
          ),
          _c("v-data-table", {
            staticClass: "rounded-0 pb-4",
            attrs: {
              headers: _vm.headers,
              items: _vm.payloadObjs,
              color: "success",
              "fixed-header": "",
              "hide-default-footer": "",
              "item-class": _vm.itemRowBackground,
              "item-key": "id",
              "loader-height": "5",
              "loading-text": "Searching Available Data...Please Wait",
              loading: _vm.loading,
              "no-data-text": "No Data to Display",
              dense: ""
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }