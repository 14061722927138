<template>
  <v-dialog :value="value" persistent @click:outside="close" max-width="950px">
    <v-card flat>
      <v-toolbar>
        <v-toolbar-title>
          <v-card-title>
            {{ `"${selectedContract.file_name}" Payload` }}
          </v-card-title>
        </v-toolbar-title>
        <v-spacer/>
        <v-btn icon @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-data-table 
        :headers="headers"
        :items="payloadObjs"
        class="rounded-0 pb-4"
        color="success" fixed-header
        hide-default-footer
        :item-class="itemRowBackground"
        item-key="id"
        loader-height="5"
        loading-text="Searching Available Data...Please Wait"
        :loading="loading"
        no-data-text="No Data to Display"
        dense>
      </v-data-table>
    </v-card>
  </v-dialog>
</template>
<script>
import { displayAlert } from '@/mixins/alert'
import { utils } from '@/mixins/utils'
import { partySearch } from '../../mixins/party-search';

export default {
  name: 'ContractPayloadModal',
  mixins: [displayAlert, utils, partySearch],
  props: {
    value: Boolean,
    selectedContract: Object
  },
  data: () => ({
    loading: true,
    payloadObjs: [],
  }),
  computed: {
    headers() {
      return [
        { text: 'Vendor', groupable: false, align: 'center', sortable: false, class: 'accent white--text', value: 'vendor', show: this.selectedContract.payload.vendor_party_id },
        { text: 'Subvendor', groupable: false, align: 'center', sortable: false, class: 'accent white--text', value: 'subvendor', show: this.selectedContract.payload.subvendor_party_id },
        { text: 'Category Manager', groupable: false, align: 'center', sortable: false, class: 'accent white--text', value: 'categoryManager', show: this.$auth.tenant === 'pricechopper' && this.selectedContract.payload.category_manager_id },
        { text: 'Private Label Contract', groupable: false, align: 'center', sortable: false, class: 'accent white--text', value: 'multiVendor', show: this.selectedContract.payload.multi_vendor},
        { text: 'Non-Allowance Contract', groupable: false, align: 'center', sortable: false, class: 'accent white--text', value: 'nonAllowance', show: this.selectedContract.payload.non_allowance},
      ].filter(header => header.show)
    }
  },
  async created() {
    await this.handlePayloadValues();
  },
  methods: {
    close() {
      this.$emit('closePayloadModal')
    },

    async handlePayloadValues() {
      const { vendor_party_id, subvendor_party_id, category_manager_id, multi_vendor, non_allowance } = this.selectedContract.payload;
      try {
        const partyIds = [vendor_party_id, subvendor_party_id, category_manager_id].filter(Boolean);

        const parties = partyIds.length > 0 ? await this.getPartyDetailsFromIds(partyIds) : [];

          this.payloadObjs.push({
            vendor: parties[0]?.display_name,
            subvendor: parties[1]?.name,
            categoryManager: multi_vendor ? parties[0]?.display_name : parties[2]?.name,
            multiVendor: multi_vendor,
            nonAllowance: non_allowance
          })
      } catch (err) {
        this.handleError(err)
      }
      finally {
        this.loading = false;
      }
    },
    
    itemRowBackground() {
      return 'grey lighten-2';
    },
  }
}
</script>